<template>
  <div class="page">
    <div class="detailBox">
      <div class="detailBox-top">
        <div class="name">{{ detail.companyName }}</div>
        <div class="time">{{ detail.createTime }}</div>
      </div>
      <div class="detailBox-center">地点：{{ detail.visitingAddress }}</div>
      <div class="detailBox-bottom">
        <div
          class="img"
          v-for="(item, index) in detail.imageUrl.split(',')"
          :key="index"
        >
          <img :src="item" alt="" />
        </div>
      </div>
    </div>
    <div class="btn" @click="toAdd()">添加走访</div>
  </div>
</template>

<script>
import {
  getVisPerInfoByMobileUrl,
  deleteRecInfoUrl,
  getRecDetailsUrl,
  getRecInfoListUrl,
  getCompanyListUrl,
  addVisRecInfoUrl,
  userInfoUrl,
} from "./api.js";
export default {
  name: "myVisitDetail",
  data() {
    return {
      id: null,
      detail: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let params = {
        id: this.id,
      };
      this.$axios.get(`${getRecDetailsUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.detail = res.data;
        }
      });
    },
    toAdd() {
      this.$router.push({
        name: "addMyVisit",
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  padding: 20px 30px 200px;
  box-sizing: border-box;
  background: #fafafa;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9490ff 0%, #5f5bff 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .detailBox {
    width: 100%;
    padding: 32px 44px 54px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    .detailBox-bottom {
      width: 100%;
      .img {
        width: 100%;
        margin-bottom: 26px;
        img {
          width: 100%;
        }
      }
    }
    .detailBox-center {
      padding: 6px 0 14px;
      font-size: 24px;
      color: #333333;
    }
    .detailBox-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        font-size: 28px;
        color: #333333;
      }
      .time {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
